
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Panel from "primevue/panel";
import Message from "primevue/message";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import crypto from "crypto";
import Footer from "@/components/Footer.vue";
import LoginService from "@/services/LoginService";
import StatusService from "@/services/StatusService";
const service = new LoginService(process.env.VUE_APP_AZURE_FUNC_URL);
const statusService = new StatusService(process.env.VUE_APP_AZURE_FUNC_URL);
import LoginResponse from "@/types/response/login";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "LoginForm",
  components: {
    InputText,
    Password,
    Button,
    Panel,
    Footer,
    Message,
  },
  computed: {
    ...mapGetters({
      styles: "styles/getStyles"
    }),
    loginActive(): any {
      return {
        grey: this.buttonText !== "Login",
        white: this.buttonText === "Login",
      };
    },
    registerActive(): any {
      return {
        grey: this.buttonText !== "Register",
        white: this.buttonText === "Register",
      };
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      Email: "",
      Password: "",
      submitted: false,
      buttonText: "Login",
      PasswordMatch: "",
      error: "",
      loading: false,
    };
  },
  async created() {
    await statusService
      .status()
      .catch((error) => {
        this.error = error.message || "Server error. Please try again later.";
      });
    setInterval(() => {
      statusService
      .status()
      .catch((error) => {
        this.error = error.message || "Server error. Please try again later.";
      });
    }, 240000);
  },
  validations() {
    return {
      Email: { required },
      Password: { required },
      PasswordMatch: { sameAsPassword: sameAs(this.Password) },
    };
  },
  methods: {
    handleLogin(
      isFormValid: boolean,
      isEmailValid: boolean,
      isPasswordValid: boolean
    ) {
      this.submitted = true;
      if (this.buttonText === "Register" && isFormValid) {
        this.postRegister();
      }
      if (this.buttonText === "Login" && isEmailValid && isPasswordValid) {
        this.postLogin();
      }
      return;
    },
    async postRegister() {
      this.loading = true;
      await service
        .register({
          Email: this.Email,
          EncryptedPassword: this.encryptPassword(this.Password),
          Client: this.styles.client_name,
        })
        .then((response: LoginResponse) => {
          if(response.error) {
            throw new Error(response.error);
          }

          let url = new URL("" + process.env.VUE_APP_CUSTOMER_PORTAL_URL);
          const payload = {
            session: btoa(
              JSON.stringify({
                name: this.styles.client_name,
                user: response.contact,
                customers: response.custs,
                subKey: response.subscription_key,
                loginUrl: "" + process.env.VUE_APP_LOGIN_URL,
                subscriptionClient: response.subscriptionClient,
                theme: {
                  primaryColor: this.styles.primary_color,
                  backgroundColor: this.styles.background_color,
                  secondaryColor: this.styles.secondary_color,
                  sidebarTextColor: this.styles.sidebar_text_color,
                  logo: this.styles.logo,
                  title: this.styles.client_name,
                  sidebarBGColor: this.styles.sidebar_bg_color,
                  tabLogo: this.styles.tab_logo,
                },
              })
            ),
          };
          const paramString = new URLSearchParams(payload);
          location.replace(url + "?" + paramString);

        }).catch((error) => {
          this.error = error.message || "Server error. Please try again later.";
        }).finally(() => {
          this.loading = false;
        });
    },
    async postLogin() {
      this.loading = true;
      await service
        .login({
          Email: this.Email,
          Password: this.Password,
          EncryptedPassword: this.encryptPassword(this.Password),
          Client: this.styles.client_name,
        })
        .then((response: LoginResponse) => {
          if(response.error) {
            throw new Error(response.error);
          }

          let url;
          if (response.user) {
            url = new URL("" + process.env.VUE_APP_WEB_CLIENT_URL);
          } else {
            url = new URL("" + process.env.VUE_APP_CUSTOMER_PORTAL_URL);
          }
          const payload = {
            session: btoa(
              JSON.stringify({
                name: this.styles.client_name,
                user: response.user ? response.user : response.contact,
                customers: response.custs,
                subKey: response.subscription_key,
                roverJwt: response.token,
                subscriptionClient: response.subscriptionClient,
                loginUrl: "" + process.env.VUE_APP_LOGIN_URL,
                theme: {
                  primaryColor: this.styles.primary_color,
                  backgroundColor: this.styles.background_color,
                  secondaryColor: this.styles.secondary_color,
                  sidebarTextColor: this.styles.sidebar_text_color,
                  logo: this.styles.logo,
                  title: this.styles.client_name,
                  sidebarBGColor: this.styles.sidebar_bg_color,
                  tabLogo: this.styles.tab_logo,
                },
              })
            ),
          };
          const paramString = new URLSearchParams(payload);
          location.replace(url + "?" + paramString);

        }).catch((error) => {
          this.error = error.message || "Server error. Please try again later.";
        }).finally(() => {
          this.loading = false;
        });
    },
    encryptPassword(password: string) {
      return crypto.createHash("sha512").update(password).digest("hex");
    },
  },
});
