import axios, { AxiosInstance } from "axios";

import LoginResponse from "@/types/response/login";

export default class LoginService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl?: string) {
    if (!serviceUrl) {
      throw new Error("You must specify a serviceUrl")
    }

    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
  }

  async login(payload: unknown): Promise<LoginResponse> {
    return this.submit<LoginResponse>("login", payload);
  }

  async register(payload: unknown): Promise<LoginResponse> {
    return this.submit<LoginResponse>("register", payload);
  }

  // Helpers
  private async submit<T>(endpoint: string, payload: unknown) {
    return new Promise<T>((resolve, reject) => {
      this.client
        .post(endpoint, payload,{})
        .then(resp => resolve(resp.data))
        .catch(error => reject(error));
    });
  }
}